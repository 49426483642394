<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <h5 class="big-title">
        電子表單管理
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="申請人姓名">
          <b-input
            v-model.trim="searchName"
            placeholder="請輸入姓名"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="申請人身分證">
          <b-input
            v-model.trim="searchId"
            placeholder="請輸入身分證字號"
          ></b-input>
        </b-input-group>
        <b-button variant="primary" @click="getData">查詢</b-button>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headersContent"
        :key="`headersContent${i}`"
        :title="item.name"
        @click="sort(item)"
      >
        {{ item.name }}
        <span v-show="item.sortDesc === null" v-if="item.key">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false" v-if="item.key">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc" v-if="item.key">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#ffffff ;' : 'background-color: #efefef;'
      "
    >
      <div style="padding: 0px 0 0 30px;-webkit-box-orient: horizontal;">
        <b-button
          variant="success"
          size="sm"
          @click="openModal(2, item)"
          :disabled="item.ApplyStatus != 2"
          class="mr-2"
          >檢視</b-button
        >
        <b-button
          variant="primary"
          size="sm"
          @click ="approveItem(item)"
          class="mr-2"
          :disabled="item.ApplyStatus != 2"
          >核准</b-button
        >
        <b-button
          variant="danger"
          size="sm"
          @click ="OUTOpenModal(item)"
          class="mr-2"
          :disabled="item.ApplyStatus != 2"
          >退回</b-button
        >
      </div>

      <div :title="item.ApplyName">
        {{ item.ApplyName || "" }}
      </div>

      <div :title="item.ApplyID">
        {{ item.ApplyID || "" }}
      </div>
      
      
      <div :title="item.HealthName">
        {{ item.HealthName || "" }}
      </div>

      <div :title="item.Department">
        {{ item.Department || "" }}
      </div>
      <div>
        {{ item.ServDate_S ? $twDate(item.ServDate_S) : "" }} ~ {{ item.ServDate_E ? $twDate(item.ServDate_E) : "" }}
      </div>
      <div :title="item.Remark">
        {{ item.Remark || "" }}
      </div>
      
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
    <!--${modalType == 1 ? '新增' : '修改'}-->
    <div id="檢視">
      <b-modal
        id="editModal"
        :title="`分發訓練申請書`"
        size="lg"
      >
        <div class="modal-grid-2">
          <!--b-input-group prepend="申請類別">
            <b-form-select v-model="modalItem.ApplyCategory">
              <option value="T">訓練</option>
              <option value="S">服務</option>
            </b-form-select>
          </b-input-group-->
          <b-input-group prepend="申請人姓名" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.ApplyName" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="電話" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.ApplyTel" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="地址" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.ApplyAddrs" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="Email" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.Applyemail" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="身分證字號" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.ApplyID" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="訓練醫院" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.HealthName" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="科別" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.Department" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="訓練時間" style="grid-column: 1/-2;" class="mt-2">
            <b-input v-model="modalItem.ServDate_S" readonly></b-input>
            <span class="mx-2">~</span>
            <b-input v-model="modalItem.ServDate_E" readonly></b-input>
          </b-input-group>
          <br/>
          <!--b-input-group prepend="服務時間" class="mt-2">
            <date-picker-tw
              :time="modalItem.ServDate_S"
              @update="(t) => (modalItem.ServDate_S = t)"
              :class="modalItem.isForever ? 'avoid-clicks' : ''"
            ></date-picker-tw>
          </b-input-group-->
          <!--div class="mt-2" style="grid-column: 1/-1">
            <quill-editor
              v-model="modalItem.Content"
              ref="myQuillEditor"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            >
            </quill-editor>
          </div-->
          <b-input-group prepend="備註" style="grid-column: 1/-1;" class="mt-2">
            <b-input v-model="modalItem.Remark" readonly></b-input>
          </b-input-group>
          <b-input-group prepend="佐證資料" style="grid-column: 1/-1;" class="mt-2">
            <b-input 
              v-model="modalItem.fileName" 
              readonly
              class="custom-file-label">
            </b-input>
            <div class="input-group-append">
              <b-button 
                variant="primary" 
                @click="viewPDF" 
                :disabled="!modalItem.fileName">
                查看 PDF
              </b-button>
            </div>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="$bvModal.hide('editModal')"
              >確定</b-button
            >
            <!--b-button
              variant="primary"
              class="float-right mr-1"
              @click="saveModal"
              >儲存</b-button
            -->
          </div>
        </template>
      </b-modal>
    </div>

    <div id="退回">
      <b-modal
        id="OutModal"
        :title="`退回`"
      >
        <div class="modal-grid-2">
          <b-input-group prepend="申請人" style="grid-column: 1/-1;" class="mt-2">
            <b-input v-model="modalItem.ApplyName" readonly></b-input>
          </b-input-group>
          <b-input-group style="grid-column: 1/-1;" class="mt-2">
            <template #prepend>
              <span class="input-group-text">退件原因</span>
            </template>
            <b-form-textarea
              v-model="modalItem.Reason"
              rows="5"
              max-rows="5"
              class="flex-grow-1"
            ></b-form-textarea>
          </b-input-group>
        </div>
        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button
              variant="danger"
              class="float-right mr-1"
              @click="OutItem(modalItem)"
              >送出</b-button
            >
          </div>
        </template>
      </b-modal>
    </div>
  </section>
</template>

<script>
import buildQuery from "odata-query";

// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import {
  domainObject,
} from "@/constant.js";

const headersContent = [
  { name: "操作", key: "", sortDesc: null },
  { name: "申請人姓名", key: "ApplyName", sortDesc: null },
  { name: "申請人身分證字號", key: "ApplyID", sortDesc: null },
  { name: "訓練醫院", key: "HealthName", sortDesc: null },
  { name: "科別", key: "Department", sortDesc: null },
  { name: "服務期間", key: "ServDate_S", sortDesc: null },
  //{ name: "訓練期間(迄)", key: "TrainDate_S", sortDesc: null },
  { name: "備註", key: "Remark", sortDesc: null },
];
const rows = [10, 20, 50];

export default {
  name: "downarea",
  data() {
    return {
      //main-content
      uploadedFileName: '',
      headersContent,
      items: [],
      //ApplyCategory: null, 
      //footer
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      rows,
      totalCountStr: "",

      //modal
      modalItem: {
        SeqNo: "",
        ApplyCategory: "",
        ApplyStatus: "",
        ApplyName: "",
        ApplyID: "",
        ApplyAddrs: "",
        ApplyTel: "",
        TrainCategory: "",
        HealthName: "",
        Department: "",
        TrainDate_S: "",
        TrainDate_E: "",
        ServDate_S: "",
        ServDate_E: "",
        Remark: "",
        CreateDate: "",
        Reason: "",
        Applyemail: "",
      },
      modalType: 1,
      searchStatus: "",
      //search
      searchSubject: "",
      searchRemark: "",
      searchUnit: null,
      searchName: "",
      searchId: "",
      //searchOptions or ModalOptions

      content: "<h2></h2>",
      editorOption: {
        // some quill options
      },
      healthCodeOptions: [],
      domainObject,
    };
  },
  components: {
    quillEditor,
    Treeselect,
  },
  computed: {
    healthCodeCheck() {
      const loginHealthCode = sessionStorage
        .getItem("darcok")
        .replace(/\"/g, "");
      const opsHpitalArr = JSON.parse(sessionStorage.getItem("opsHpital"));
      const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
      const roleHealthBureauArr = JSON.parse(
        sessionStorage.getItem("roleHealthBureau")
      );
      const roleHealthCenter = JSON.parse(
        sessionStorage.getItem("roleHealthCenter")
      );
      const allArr = [
        ...opsHpitalArr,
        ...stuChoolArr,
        ...roleHealthBureauArr,
        ...roleHealthCenter,
      ];
      return allArr.includes(loginHealthCode);
    },
  },

  methods: {
    sort(item) {
      if (!item.key) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];

      this.headersContent.forEach((s) => {
        if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });

      this.getData();
    },
    viewPDF() {
      if (this.modalItem.fileName) {
        const fileUrl = `${this.domainObject.platformOriginal}${this.modalItem.URL}`;
        // 打開 PDF 文件
        window.open(fileUrl, '_blank');
      }
    },
    async openModal(type, item) {
      const codeNow = sessionStorage.getItem("darcok").replace(/\"/g, "");
      this.modalType = type;
      if (type == 1) {
        this.modalItem = {
          //ApplyCategory: "",
          HealthName: "",
          Department: "",
          ServDate_S: "",
          ServDate_E: "",
          Remark: "",
          ApplyAddrs: "",
          ApplyTel: "",
          TrainCategory: "",
          URL: "",
          //ServDate_S: "",
          fileName: "",
          ApplyName: "",
          ApplyID: "",
          Applyemail: "",
        };
      } else {
        this.modalItem = JSON.parse(JSON.stringify(item));
        this.modalItem.ServDate_S = this.$twDate(item.ServDate_S);
        this.modalItem.ServDate_E = this.$twDate(item.ServDate_E);
        //this.modalItem.ServDate_S = this.$twDate(item.ServDate_S);
        this.modalItem.fileName = this.modalItem.URL.split('\\').pop().split('/').pop();
        //console.log(modalItem.fileName);
      }
      this.$bvModal.show("editModal");
    },
    async OUTOpenModal(item) {
      const codeNow = sessionStorage.getItem("darcok").replace(/\"/g, "");
      this.modalItem = JSON.parse(JSON.stringify(item));
      this.modalItem.fileName = this.modalItem.URL.split('\\').pop().split('/').pop();
      this.$bvModal.show("OutModal");
    },
    async saveModal() {
      //const url = `api/applyform5`;
      const obj = {
        ...this.modalItem,
      };
      try {
        const response = await window.axios.put('applyform5/Put', obj);
        console.log("API response:", response); // 檢查 API 響應
        this.$bvToast.toast(
          `${obj.ApplyStatus != 1 ? "核准" : "退回"}成功`,
          {
            title: "系統資訊",
            autoHideDelay: 5000,
            variant: "success",
          }
        );
        this.getData();
        this.$bvModal.hide("editModal");
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async deleteModal(item) {
      const url = `applyform5/Delete?Id=${item.SeqNo}`;

      try {
        await window.axios.delete(url);
        this.$bvToast.toast(`刪除成功`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "success",
        });
        this.getData();
      } catch (e) {
        this.$bvToast.toast(`${e.response ? e.response.data : e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      qs = `${qs}&$filter=ApplyCategory eq 'S' and (ApplyStatus eq '2' or ApplyStatus eq '3')`;

      if (this.searchName) {
        qs = this.$appendSubstringofFilter("ApplyName", qs, this.searchName);
      }
      if (this.searchId) {
        qs = this.$appendSubstringofFilter("ApplyID", qs, this.searchId);
      }
      try {
        const { Items, Count } = await window.axios.get(
          `applyform5/Get${qs}`
        );
          // 修改資料處理部分
        this.items = Items.map((s) => ({
            SeqNo: s.SeqNo || "",
            ApplyCategory: s.ApplyCategory || "",
            ApplyStatus: s.ApplyStatus || "",
            //gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
            ApplyName: s.ApplyName || "",
            ApplyID: s.ApplyID || "",
            ApplyAddrs: s.ApplyAddrs || "",
            ApplyTel: s.ApplyTel || "",
            TrainCategory: s.TrainCategory || "",
            HealthName: s.HealthName || "",
            Department: s.Department || "",
            //TrainDate_S: s.TrainDate_S || "",
            //TrainDate_E: s.TrainDate_E || "",
            ServDate_S: s.ServDate_S || "",
            ServDate_E: s.ServDate_E || "",
            Remark: s.Remark || "",
            Reason: s.reason || "",
            URL: s.URL,
            Applyemail: s.Applyemail || "",
          }));
          //console.log(Items);
        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async approveItem(item) {
      this.modalItem = { ...item }; // 使用傳遞進來的 item 更新 modalItem
      this.modalItem.ApplyStatus = "3";
      this.saveModal();
    },
    async OutItem(item) {
      this.modalItem = { ...item }; // 使用傳遞進來的 item 更新 modalItem
      this.modalItem.ApplyStatus = "1";
      this.saveModal();
      this.$bvModal.hide('OutModal');
    },
    
  },
  async mounted() {
    //await this.getAllHealthCodeOption();
    await this.getData();
  },

  watch: {
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  padding: 12px;
  width: fit-content;
}
.dtc-grid-header,
.dtc-grid-header2 {
  display: grid;
  grid-template-columns: 220px 200px 250px 250px 250px 220px 180px ;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding: 4px 0;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    height: 33px;
  }
  > div:first-child {
    border-left: 1px solid #cccccc;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 4px;
    // padding-top: 7px;
  }
}
.custom-file-input:lang(en)~.custom-file-label::after {
  //隱藏Browse按鈕
    content: none;
  }
.my-dark {
  > div {
    background: #333;
    color: white;
  }
}

.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 32px;
  margin-bottom: 12px;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 12px;
  padding-left: 10px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 125px;
  background: #0379fd;
  color: #fff;
}

.search-block {
  //   height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }

  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: #ffffff;
  .close {
    color: #ffffff;
  }
}
.my-light {
  > div {
    background: black;
    color: black;
  }
}
.modal-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
}
::v-deep .ql-container {
  height: 460px;
}
</style>
